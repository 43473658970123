//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$body-color: #000000; // The default text color

$primary:    #FBE407; // #F1E512
$secondary:  #161616;
$success:    #198754;
$info:       #0dcaf0;
$warning:    #F1E512;
$danger:     #dc3545;
$light:      #FFFFFF;
$dark:       #000000;

//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

/*
$btn-border-radius:           $border-radius;
$btn-border-radius-sm:        $border-radius-sm;
$btn-border-radius-lg:        $border-radius-lg;
*/
$border-radius: 0.2rem;
$border-radius-sm: 0.2rem;
$border-radius-lg: 0.2rem;


/* $navbar-dark-color: #CC0000; */
$navbar-light-color: #FFFFFF;
$navbar-light-hover-color: #FFFFFF;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$navbar-brand-font-size: 3rem;


// MODALS
$modal-content-bg: $secondary;
$modal-content-border-width: 0;
$btn-close-color: $primary;
$btn-close-width: 1.5em;
$btn-close-focus-shadow: 0;

// CAROUSEL
$carousel-control-color: $light;
$carousel-control-opacity: 0.8;
$carousel-control-hover-opacity: 1;
$carousel-control-icon-width: 2.5rem;

$carousel-indicator-width:           30px;
$carousel-indicator-height:          30px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-opacity:         .3;
$carousel-indicator-active-bg:       $secondary;
$carousel-indicator-active-opacity:  1;


$carousel-caption-width:             70%;
$carousel-caption-color: $dark;
$carousel-caption-padding-y:         2rem;
$carousel-caption-spacer:            1.25rem;




// Use Bootstrap breakpoints for consistency.
$bootstrap-sm: 576px;
$bootstrap-md: 768px;
$bootstrap-lg: 992px;
$bootstrap-xl: 1200px;

// Crop thumbnail images.
#gallery {

  img {
    height: 75vw;
    object-fit: cover;

    @media (min-width: $bootstrap-sm) {
      height: 35vw;
    }

    @media (min-width: $bootstrap-lg) {
      height: 18vw;
    }
  }
}


// Crop images in lightbox.
.carousel-item {

  min-height: 400px;

  img {
    height: 60vw;
    object-fit: cover;

    @media (min-width: $bootstrap-sm) {
      height: 350px;
    }
  }
}

// --- END: crop images --- //









// None of this CSS is required for the gallery and lightbox to function.

// --- BEGIN: crop images --- //
// Optional CSS for cropping images that aren't all the same size.

// Use Bootstrap breakpoints for consistency.
$bootstrap-sm: 576px;
$bootstrap-md: 768px;
$bootstrap-lg: 992px;
$bootstrap-xl: 1200px;

// Crop thumbnail images.
#gallery {

  img {
    height: 75vw;
    object-fit: cover;

    @media (min-width: $bootstrap-sm) {
      height: 35vw;
    }

    @media (min-width: $bootstrap-lg) {
      height: 18vw;
    }
  }
}


// Crop images in lightbox.
.carousel-item {

  img {
    height: 60vw;
    object-fit: cover;

    @media (min-width: $bootstrap-sm) {
      height: 350px;
    }
  }
}

// --- END: crop images --- //





/*
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
*/

@media screen and (max-width: 319px) {

  #start {
    height: 255px;
    background-image: url('../images/dezemberkind-bandfoto.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    /*border-top: 2px solid red;*/
  }

  .navbar-brand {
    width: 120px !important;
  }

  .nav-link {
    font-family: "Bahiana", sans-serif;
    font-size: 28px;
    text-shadow: 2px 2px 8px $secondary;
    /* letter-spacing: 0.1em; */
  }

  .markierung {
    display: inline-block;
    min-width: 134px;
    text-decoration: none;
    /* margin-right: 20px; */
    padding: 20px;
    position: relative;
    text-align: center;

    &:hover {
      .button-stroke:nth-child(2) {
        stroke-dashoffset: 0;
      }
    }
  }

  .button-stroke {
    display: block;
    width: calc(100% - 40px);
    height: 10px;
    /* stroke: $secondary; */
    position: absolute;
    left: 20px;
    bottom: 18px;
    stroke-width: 3;

    &:nth-child(2) {
      stroke-dasharray: 650px;
      stroke-dashoffset: 650px;
      stroke: darken($primary, 10%);
      stroke-width: 5;
      transition: stroke-dashoffset 400ms ease-out;
    }
  }

}


@media screen and (min-width: 320px) and (max-width: 719px) {

  #start {
    height: 515px;
    background-image: url('../images/dezemberkind-bandfoto.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover !important;
    /*border-top: 2px solid orange;*/
  }

  .nav-link {
    font-family: "Bahiana", sans-serif;
    font-size: 28px;
    text-shadow: 2px 2px 8px $secondary;
    /* letter-spacing: 0.1em; */
  }

  .markierung {
    display: inline-block;
    min-width: 134px;
    text-decoration: none;
    /* margin-right: 20px; */
    padding: 20px;
    position: relative;
    text-align: center;

    &:hover {
      .button-stroke:nth-child(2) {
        stroke-dashoffset: 0;
      }
    }
  }

  .button-stroke {
    display: block;
    width: calc(100% - 40px);
    height: 10px;
    /* stroke: $secondary; */
    position: absolute;
    left: 20px;
    bottom: 18px;
    stroke-width: 3;

    &:nth-child(2) {
      stroke-dasharray: 650px;
      stroke-dashoffset: 650px;
      stroke: darken($primary, 10%);
      stroke-width: 5;
      transition: stroke-dashoffset 400ms ease-out;
    }
  }

}


@media screen and (min-width: 720px) {

  #start {
    height: 100vh !important;
    background-image: url('../images/dezemberkind-bandfoto.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover !important;
    /*border-top: 2px solid green;*/
  }

  .nav-link {
    font-family: "Bahiana", sans-serif;
    font-size: 28px;
    text-shadow: 2px 2px 8px $secondary;
    /* letter-spacing: 0.1em; */
  }

  .markierung {
    display: inline-block;
    min-width: 134px;
    text-decoration: none;
    /* margin-right: 20px; */
    padding: 20px;
    position: relative;
    text-align: center;

    &:hover {
      .button-stroke:nth-child(2) {
        stroke-dashoffset: 0;
      }
    }
  }

  .button-stroke {
    display: block;
    width: calc(100% - 40px);
    height: 10px;
    /* stroke: $secondary; */
    position: absolute;
    left: 20px;
    bottom: 18px;
    stroke-width: 3;

    &:nth-child(2) {
      stroke-dasharray: 650px;
      stroke-dashoffset: 650px;
      stroke: darken($primary, 10%);
      stroke-width: 5;
      transition: stroke-dashoffset 400ms ease-out;
    }
  }

}

@media screen and (min-width: 960px) {

  #start {
    height: 100vh !important;
    background-image: url('../images/dezemberkind-bandfoto.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover !important;
    /*border-top: 2px solid blue;*/
  }

  .nav-link {
    font-family: "Bahiana", sans-serif;
    font-size: 28px;
    text-shadow: 2px 2px 8px $secondary;
    /* letter-spacing: 0.1em; */
  }

  .markierung {
    display: inline-block;
    min-width: 134px;
    text-decoration: none;
    /* margin-right: 20px; */
    padding: 20px;
    position: relative;
    text-align: center;

    &:hover {
      .button-stroke:nth-child(2) {
        stroke-dashoffset: 0;
      }
    }
  }

  .button-stroke {
    display: block;
    width: calc(100% - 40px);
    height: 10px;
    /* stroke: $secondary; */
    position: absolute;
    left: 20px;
    bottom: 18px;
    stroke-width: 3;

    &:nth-child(2) {
      stroke-dasharray: 650px;
      stroke-dashoffset: 650px;
      stroke: darken($primary, 10%);
      stroke-width: 5;
      transition: stroke-dashoffset 400ms ease-out;
    }
  }

}

