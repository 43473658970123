//---------------------------------------------
//
//  Fonts
//
//    1. Loading Mixins
//    2. Loading Fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Loading Mixins
//---------------------------------------------

//@import "mixins/google-fonts";

//---------------------------------------------
// 2. Loading Fonts
//---------------------------------------------


/* bahiana-regular - latin */
@font-face {
  font-family: 'Bahiana';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/bahiana/bahiana-v19-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/bahiana/bahiana-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/bahiana/bahiana-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/bahiana/bahiana-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/bahiana/bahiana-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/bahiana/bahiana-v19-latin-regular.svg#Bahiana') format('svg'); /* Legacy iOS */
}




@font-face {
	font-family: 'Century Gothic';
	src: url('../fonts/century-gothic/CenturyGothic-Italic.eot');
	src: local('Century Gothic Italic'), local('CenturyGothic-Italic'),
		url('../fonts/century-gothic/CenturyGothic-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/century-gothic/CenturyGothic-Italic.woff') format('woff'),
		url('../fonts/century-gothic/CenturyGothic-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Century Gothic';
	src: url('../fonts/century-gothic/CenturyGothic.eot');
	src: local('Century Gothic'), local('CenturyGothic'),
		url('../fonts/century-gothic/CenturyGothic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/century-gothic/CenturyGothic.woff') format('woff'),
		url('../fonts/century-gothic/CenturyGothic.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Century Gothic';
	src: url('../fonts/century-gothic/CenturyGothic-Bold.eot');
	src: local('Century Gothic Bold'), local('CenturyGothic-Bold'),
		url('../fonts/century-gothic/CenturyGothic-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/century-gothic/CenturyGothic-Bold.woff') format('woff'),
		url('../fonts/century-gothic/CenturyGothic-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Century Gothic';
	src: url('../fonts/century-gothic/CenturyGothic-BoldItalic.eot');
	src: local('Century Gothic Bold Italic'), local('CenturyGothic-BoldItalic'),
		url('../fonts/century-gothic/CenturyGothic-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/century-gothic/CenturyGothic-BoldItalic.woff') format('woff'),
		url('../fonts/century-gothic/CenturyGothic-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}




/*
@include googleFont((
  "name": "Roboto",
  "folder": "Roboto",
  "files": "Roboto",
  "weights": (300, 400, 700)
));
*/

/*
 * Example for loading multiple fonts at once
 *
@include googleFonts((
  (
    "name": "Roboto",
    "folder": "Roboto",
    "files": "Roboto",
    "weights": (300, 400, 700)
  ),
  (
    "name": "Besley",
    "folder": "Besley",
    "files": "Besley",
    "weights": (300, 400, 700)
  ),
));
*/