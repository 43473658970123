//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------


::selection {
  color: $primary;
  background-color: $secondary;
}


::-webkit-scrollbar {
	width: 5px;
}
::-webkit-scrollbar-track {
	background: $secondary;
}
::-webkit-scrollbar-thumb {
	background: lighten($secondary, 20%);
}
::-webkit-scrollbar-thumb:hover {
	background: $secondary;
}




body {
    background-color: $secondary;
    font-family: "Century Gothic", sans-serif;
    font-size: 18px;
    color: $light;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Bahiana", sans-serif;
}

h1 {
	font-size: 48px;
}
h2 {
	font-size: 32px;
}


// OFF-CANVAS
.offcanvas {
  background-color: #161616 !important;
}

.offcanvas.offcanvas-end {
  width: 100% !important;
}

.btn-close {
  color: $primary;
}


// NAVIGATION

.nav-link {
  font-family: "Bahiana", sans-serif;
  font-size: 28px;
  text-shadow: 2px 2px 8px $secondary;
  /* letter-spacing: 0.1em; */
}

.nav-link.active {
  /* border-bottom: 1px solid gray; */
}




.markierung {
	display: inline-block;
	/* min-width: 134px; */
	min-width: 120px;
	text-decoration: none;
	/* margin-right: 20px; */
	padding: 20px 0px;
	position: relative;
	text-align: center;

	&:hover {
		.button-stroke:nth-child(2) {
			stroke-dashoffset: 0;
		}
	}
}

// Wenn Seitenbreite über 1199px, dann für die Klasse .markierung die min-width auf 134px setzen
@media (min-width: 1120px) {
  .markierung {
    min-width: 134px;
  }
}




.button-stroke {
	display: block;
	width: calc(100% - 40px);
	height: 10px;
	/* stroke: $secondary; */
	position: absolute;
	left: 20px;
	bottom: 18px;
	stroke-width: 3;

	&:nth-child(2) {
		stroke-dasharray: 650px;
		stroke-dashoffset: 650px;
		stroke: darken($primary, 10%);
		stroke-width: 5;
		transition: stroke-dashoffset 400ms ease-out;
	}
}



#vierhundertvier {
  font-family: "Bahiana", sans-serif;
	font-size: 10rem;
	color: $white;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  text-align: center !important;
  z-index: 99;
}

#comingsoon {
	font-family: "Bahiana", sans-serif;
	font-size: 6rem;
	color: $dark;
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    /*position: absolute;*/
	position: relative;
  width: 100%;
  text-align: center !important;
  bottom: 180px;
  z-index: 99;
}

#logo {
    position: absolute;
    width: 100%;
    text-align: center !important;
    bottom: 165px;
    z-index: 99;
}

#logo img {
  width: 50%;
}


#cd {
  /*border: 1px solid red;*/
  height: 100%;
  width: 100%;
}

/*
#start:before {
  position: relative;
  height: 250px;
  opacity: 1;
  top: -120px;
  display: block;
  background: url("../images/divider/divider01b.png");
  background-color: transparent !important;
  content: "";
  rotate: 180deg;
  transform: scaleY(-1);
}
*/


#start:after {
  content: "";
  display: block;
  height: 180px;
}


#contentcomingsoon:not(:first-of-type):before {
	position: relative;
	height: 200px;
	opacity: 1;
	top: -160px;
	display: block;
	background: url("../images/divider/divider01a.png");
	background-color: transparent !important;
	content: "";
}

#contentcomingsoon:after {
	content: "";
	display: block;
	height: 180px;
}

#news:not(:first-of-type):before, #teasersingle:not(:first-of-type):before {
  position: relative;
  height: 200px;
  opacity: 1;
  top: -160px;
  display: block;
  background: url("../images/divider/divider01a.png");
  background-color: transparent !important;
  content: "";
}

#news:after, #teasersingle:after {
  content: "";
  display: block;
  height: 180px;
}

#media:not(:first-of-type):before {
  position: relative;
  height: 257px;
  opacity: 1;
  display: block;
  background: url("../images/divider/divider01b.png");
  background-color: transparent !important;
  content: "";
}










.wrapper {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}

.flipcard {
	width: 400px;
	height: 400px;
	margin: 1em;
	perspective: 1500px;
  background-color: transparent !important;
	.content {
		position: relative;
		width: 100%;
		height: 100%;
		transform-style: preserve-3d;
		transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1);
	}
}

.more {
	display: none;

	&:checked ~ .content {
		transform: rotateY(180deg);
	}
}

.front,
.back {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	transform-style: preserve-3d;
	border-radius: 6px;

	.inner {
		height: 100%;
		display: grid;
		padding: 1.5em;
		transform: translateZ(80px) scale(0.94);
	}
}

.front {
	background-color: #fff;
	background-size: cover;
	background-position: center center;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		border-radius: 6px;
		backface-visibility: hidden;
	}
	.inner {
		grid-template-rows: 2fr 1fr 1fr 1fr 1fr;

		justify-items: center;
	}

	h2 {
		grid-row: 2;
		margin-bottom: 0.3em;
		text-transform: uppercase;
		letter-spacing: 3px;
		color: #fff;
		font-weight: 500;
		text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
	}

}

.back {
  color: $dark;
	transform: rotateY(180deg);
	background-color: $primary;
	border: 2px solid $dark;
	.inner {
		grid-template-rows: 2fr 1fr 1fr 1fr 8fr;
		grid-column-gap: 0.8em;
		justify-items: center;
	}

	.info {
		position: relative;
		display: flex;
		align-items: center;
		color: $primary;
		grid-row: 3;
		&:not(:first-of-type):before {
			content: '';
			position: absolute;
			left: -0.9em;
			height: 18px;
			width: 1px;
			background-color: #ccc;
		}
		span {
			font-size: 2em;
			font-weight: 700;
		}
		i {
			&:before {
				background: linear-gradient(40deg, $primary, rgb(67, 138, 243));

				-webkit-text-fill-color: transparent;
				-webkit-background-clip: text;
			}
			font-size: 1.2em;
		}
		.icon {
			margin-left: 0.3em;
			span {
				display: block;
				margin-top: -0.25em;
				font-size: 0.8em;
				font-weight: 600;
				white-space: nowrap;
			}
		}
	}

	.description {
		font-weight: 500;
		line-height: 1.4em;
		color: $dark;
	}

	.button {
		grid-column: 1/-1;
		justify-self: center;
	}
}

.button {
	grid-row: -1;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 600;
	cursor: pointer;
	display: block;
	padding: 0 1.5em;
	height: 3em;
	line-height: 2.9em;
	min-width: 3em;
	background-color: $light;
	border: solid 2px $dark;
	color: $dark;
	border-radius: 4px;
	text-align: center;
	left: 50%;
	backface-visibility: hidden;
	transition: 0.3s ease-in-out;
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);

	&:hover {
		background-color: $primary;
    border-color: $dark;
    color: $dark;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
		text-shadow: none;
	}

	&.return {
		line-height: 3em;
		color: $secondary;
		border-color: $secondary;
		text-shadow: none;
		&:hover {
			background-color: $secondary;
			color: #fff;
			box-shadow: none;
		}
	}
}












/* IMAGE GRID STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.image-grid figure {
  margin-bottom: 0;
}

.image-grid img {
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.2s;
}

.image-grid a:hover img {
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.35);
}


/* LIGHTBOX STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.lightbox-modal .modal-content {
  background: var(--lightbox);
}

.lightbox-modal .btn-close {
  position: absolute;
  top: 20px;
  right: 18px;
  font-size: 1.2rem;
  z-index: 10;
}

.lightbox-modal .modal-body {
  display: flex;
  align-items: center;
  padding: 0;
  text-align: center;
}

.lightbox-modal img {
  width: auto;
  max-height: 100vh;
  max-width: 100%;
}

/*
.lightbox-modal .carousel-caption {
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(36, 36, 36, 0.75);
}
*/

.lightbox-modal .carousel-control-prev,
.lightbox-modal .carousel-control-next {
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  width: auto;
}

.lightbox-modal .carousel-control-prev {
  left: 10px;
}

.lightbox-modal .carousel-control-next {
  right: 10px;
}


/* FOOTER STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  position: fixed;
  right: 0;
  bottom: 60px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 5px;
  background: rgba(255, 255, 255, 0.65);
}

.page-footer a {
  display: flex;
  margin-left: 9px;
}



/*
  CAROUSEL
*/


.carousel-caption {
	position: initial !important;
  text-align: left;
}



.carousel-arrows-container-prev {
  position: absolute;
  bottom: -7px;
  left: 10px;
  display: flex;
  gap: 10px;
}
.carousel-arrows-container-next {
  position: absolute;
  bottom: -7px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.carousel-control-prev,
.carousel-control-next {
  width: 40px; /* Anpassen, um die Breite der Pfeile nach Bedarf zu ändern */
  height: 40px; /* Anpassen, um die Höhe der Pfeile nach Bedarf zu ändern */
}


.carousel-indicators {
  position: absolute;
  bottom: -70px;
}


//---------------------------------------------
// 3.Components
//---------------------------------------------

//@import "components/slider";



/*
.a-glow {
    background-color: $dark;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: none;
    color: $light;
    cursor: pointer;
    display: inline-block;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    -webkit-animation: glowing 3500ms infinite;
    -moz-animation: glowing 3500ms infinite;
    -o-animation: glowing 3500ms infinite;
    animation: glowing 3500ms infinite;
}

.a-glow:hover {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: none;
	color: $light;
    cursor: pointer;
    display: inline-block;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    -webkit-animation: glowing 3500ms infinite;
    -moz-animation: glowing 3500ms infinite;
    -o-animation: glowing 3500ms infinite;
    animation: glowing 3500ms infinite;
}


@-webkit-keyframes glowing {
    0% { background-color: $primary; -webkit-box-shadow: 0 0 3px $primary; }
    50% { background-color: $dark; -webkit-box-shadow: 0 0 40px $dark; }
    100% { background-color: $primary; -webkit-box-shadow: 0 0 3px $primary; }
}

@-moz-keyframes glowing {
    0% { background-color: $primary; -moz-box-shadow: 0 0 3px $primary; }
    50% { background-color: $dark; -moz-box-shadow: 0 0 40px $dark; }
    100% { background-color: $primary; -moz-box-shadow: 0 0 3px $primary; }
}

@-o-keyframes glowing {
    0% { background-color: $primary; box-shadow: 0 0 3px $primary; }
    50% { background-color: $dark; box-shadow: 0 0 40px $dark; }
    100% { background-color: $primary; box-shadow: 0 0 3px $primary; }
}

@keyframes glowing {
    0% { background-color: $primary; box-shadow: 0 0 3px $primary; }
    50% { background-color: $dark; box-shadow: 0 0 40px $dark; }
    100% { background-color: $primary; box-shadow: 0 0 3px $primary; }
}






.flashing{
	background:black;
	padding:20px;
	color:#fff;
	display:inline-block;
	animation-name: flash;
	animation-duration: 3.5s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

.flashing.one {
	animation-delay: 3s;
}

.flashing.two {
	animation-delay: 6s;
}

@keyframes flash {
	0% { background: $dark; }
	50% { background:$primary; }
	100% { background: $dark; }
}
*/





#timeline {
	display: inline-block;
	float: left;
	width: 85%;
	height: 2px;
	margin-top: 0px;
	margin-left: 10px;
	-webkit-border-radius: 15px;
	border-radius: 15px;
	background: $primary;
}
#timeline:hover {
	background: $light;
}

#playhead {
	width: 8px;
	height: 8px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	margin-top: -3px;
	background: $primary;
	cursor: pointer;
}
#playhead:hover {
	background: $light;
}


.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-flow: nowrap; */
  flex-wrap: nowrap;
  align-content: stretch;
  /*background-color: #bbdefb;*/
  height: 100%;
  /*padding: 15px;*/
  /* gap: 5px; */
}

.flex-container > div{
  /*background: #ffecb3;*/
  /* border-radius: 5px; */
  /*padding: 8px;*/
}


.item1 {
  /* flex:0 1 auto; */
  align-self:center;
}

.item2 {
  /* flex:1 1 auto; */
  flex-grow:1;
  align-self:center;
}

.playbutton {
  fill: $primary;
}
.playbutton:hover {
  fill: $light;
}




  /*
    SOCIAL SIDEBAR
  */

  .mf-social-side-list {
    position: fixed;
    bottom: 30vh;
    right: 10px;
    z-index: 100;
  }
  .mf-social-side-list ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin:0
  }
  .mf-social-side-list ul li a {
    color: #ffffff;
    /* border-radius: 50%; */
    width: 45px;
    height: 45px;
    line-height: 45px;
    margin-top: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    }
    .mf-social-side-list ul li a:hover {
      opacity: .9;
      -webkit-transform: translateX(-8px);
      transform: translateX(-8px);
    }
    .mf-social-side-list ul li a[href*='facebook'] {
      background: #3b5998;
      /*background: $primary;*/
      background: $black;
    }
    .mf-social-side-list ul li a[href*='instagram'] {
      background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);;
      background: $black;
    }
    .mf-social-side-list ul li a[href*='youtube'] {
      background: #c4302b;
      background: $black;
    }
    .mf-social-side-list ul li a[href*='spotify'] {
        background: #1ed760;
        background: $black;
    }
    .mf-social-side-list ul li a[href*='twitter'] {
        background: #00acee;
        background: $black;
    }



/*
    YOUTUBE-BLOCKER
*/
/*
iframe[src*="youtu"], iframe[src*="vimeo"],
iframe[data-src*="youtu"], iframe[data-src*="vimeo"] {
   width: 100% !important;
   max-width: 1000px;
   height: 562px !important;
}
.video_iframeoverlay {
   width: 100% !important;
   max-width: 1000px;
   height: 562px !important;
   position:relative;
  background-color: $dark;
   // background-image: linear-gradient(170deg,#1a6b80,#66b8cc);
   position:relative;
    -webkit-display:flex;
       -moz-display:flex;
        -ms-display:flex;
            display:flex;
    -webkit-flex-direction:column;
       -moz-flex-direction:column;
        -ms-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:center;
       -moz-justify-content:center;
        -ms-justify-content:center;
            justify-content:center;
}
.video_iframeoverlay p {
   padding: 0 1.5rem;
   text-align: center;
   margin: 0;
   z-index: 2;
   position:absolute;
}
.video_iframeoverlay #unlockiframe {
   background: $dark;
   color: $white;
   padding: 0.5rem;
   border-radius: 0.5rem;
   cursor: pointer;
   display: block;
   margin: 0.5rem auto 0;
   width: 12rem;
   max-width:90%;
   z-index: 2;
}
*/
/*
.video_iframeoverlay #unlockiframe:hover {
   background: #f29524;
}
*/





.headline-brush-black {
  background-image: url("../images/brush-black.png");
  background-repeat: no-repeat;
  background-size: contain;
  color: $white;
  rotate: -2deg;
  text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);
  padding-left: 0.7em;
  margin-bottom: 1em;
  height: 90px;
  padding-top: 17px;
}

.headline-brush-yellow {
  background-image: url("../images/brush-yellow.png");
  background-repeat: no-repeat;
  background-size: contain;
  color: $dark;
  rotate: -2deg;
  text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5);
  padding-left: 0.5em;
  margin-bottom: 1em;
}

